import React from "react";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-left">
        <p className="copyright">
          Copyright © 2023 Calvin AI. All rights reserved.
        </p>
      </div>
      <div className="footer-right">
        <a
          href="https://www.linkedin.com/company/calvin-ai"
          target="_blank"
          rel="noopener noreferrer"
          className="linkedin-icon"
        >
          <LinkedInIcon fontSize="large" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
