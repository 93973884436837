import React from "react";
import "./GetStartedPage.css"; // Import the CSS file for styling
import { Button } from "@mui/material";

const GetStartedPage = () => {
  return (
    <div className="get-started-page">
      <h1 className="get-started-title">Get Started Today</h1>
      <p className="get-started-sub-title">
        Discover the full potential of our service without any commitment. Sign
        up today and enjoy a 7-day free trial, giving you unrestricted access to
        all our features. Experience how our solution can streamline your
        workflow, increase productivity, and revolutionise your clinical
        note-taking.
      </p>
      <a
        href="https://dev-v7a5ioospfgu6dg4.us.auth0.com/u/signup?state=hKFo2SBFTGpiV1lhdEhHVFpEakhRbVFwdXlCTm9lSWZjYzRVc6Fur3VuaXZlcnNhbC1sb2dpbqN0aWTZIFhVMEg0VnRCX09CaGhpRzdKMUhrUWg5MjdXR3luR3BKo2NpZNkgYW5hTEE3RERzam5NZHJaZ1gxa3Y0ckJmVk9iaENlNEk"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button
          variant="contained"
          disableElevation
          sx={{
            padding: "8px 18px",
            color: "black",
            background: "#FFFFFF",
            "&:hover": {
              background: "#e0e0e0",
            },
          }}
        >
          Get Started Today
        </Button>
      </a>
    </div>
  );
};

export default GetStartedPage;
