import React from "react";
import "./FAQPage.css"; // Make sure to create and link this CSS file

const FAQPage = () => {
  return (
    <div className="faq-page" id="FAQPage">
      <h1 className="faq-heading">Frequently Asked Questions</h1>
      <p className="faq-description">
        Find answers to some of the most common questions about our service. If
        you can’t find what you’re looking for, email our support team at{" "}
        <a href="mailto:team@calvin-ai.com">team@calvin-ai.com</a>.
      </p>
      <div className="faq-grid">
        {/* Row 1 */}
        <div className="faq-column">
          <h2>
            How does Calvin AI ensure the confidentiality of patient data?
          </h2>
          <p>
            We do not store any transcriptions or notes on our servers. All data
            processing is done in real-time, and once the session ends, the
            information is deleted from our systems.
          </p>
        </div>
        <div className="faq-column">
          <h2>How accurate is the AI transcription feature?</h2>
          <p>
            Our AI transcription boasts a high accuracy rate, typically above
            95%. It's trained on diverse datasets to handle different accents
            and dialects effectively.
          </p>
        </div>
        <div className="faq-column">
          <h2> How does the AI note generation feature work?</h2>
          <p>
            Our AI note generation feature uses advanced algorithms to analyse
            spoken language in real-time, identifying key points and topics. It
            then generates concise, organised notes, capturing the essence of
            the conversation or consultation without unnecessary details.
          </p>
        </div>

        {/* Row 2 */}
        <div className="faq-column">
          <h2>
            How does Calvin AI ensure compliance with healthcare regulations?
          </h2>
          <p>
            While we don't store data on our servers, we ensure that all our
            processes and functionalities comply with healthcare industry
            standards and regulations. This compliance includes ensuring data is
            handled securely and privately during every interaction with our
            system.
          </p>
        </div>
        <div className="faq-column">
          <h2>
            What measures are in place to ensure the accuracy of transcriptions?
          </h2>
          <p>
            Our AI-driven transcription service is highly accurate, even in
            understanding medical terminology. Additionally, we provide
            easy-to-use editing tools to quickly make any necessary corrections,
            ensuring that the transcriptions are as accurate as possible.
          </p>
        </div>
        <div className="faq-column">
          <h2>
            Can the system integrate with existing Electronic Health Record
            (EHR) systems?
          </h2>
          <p>
            At present, our system does not offer integration with EHR and
            Practice Management Software, such as Best Practice. We recognise
            the importance of such integrations for a seamless workflow in
            medical practices and are actively exploring solutions to include
            this functionality in future updates.
          </p>
        </div>

        {/* Row 3 */}
        <div className="faq-column">
          <h2>
            Can the AI distinguish between different speakers, such as a doctor
            and a patient?
          </h2>
          <p>
            Yes, our AI is designed to recognise different speakers and can
            differentiate between a doctor's instructions and a patient's
            responses. This ensures that the notes are structured and attributed
            correctly, enhancing clarity and usefulness.
          </p>
        </div>
        <div className="faq-column">
          <h2>How accurate are the AI-generated notes?</h2>
          <p>
            The AI-generated notes are highly accurate, but like any AI
            technology, they may require occasional manual corrections. We
            provide easy-to-use editing tools to quickly adjust any
            inaccuracies, ensuring the final notes are precise.
          </p>
        </div>
        <div className="faq-column">
          <h2>
            Is the platform easy to use for medical practitioners who aren't
            tech-savvy?
          </h2>
          <p>
            Absolutely! Our platform is designed with a user-friendly interface
            that requires minimal technical know-how. It offers straightforward
            navigation and easy access to all features, ensuring that doctors
            can focus more on patient care and less on managing technology.
          </p>
        </div>
      </div>
    </div>
  );
};

export default FAQPage;
