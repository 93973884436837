import React from "react";
import { Button } from "@mui/material";
import "./Header.css";

const Header = () => {
  return (
    <header className="header">
      <div className="header-left">
        <h4 className="logo-top-left">Calvin AI</h4>
      </div>
      <div className="header-right">
        <a
          href="https://dev-v7a5ioospfgu6dg4.us.auth0.com/u/signup?state=hKFo2SBFTGpiV1lhdEhHVFpEakhRbVFwdXlCTm9lSWZjYzRVc6Fur3VuaXZlcnNhbC1sb2dpbqN0aWTZIFhVMEg0VnRCX09CaGhpRzdKMUhrUWg5MjdXR3luR3BKo2NpZNkgYW5hTEE3RERzam5NZHJaZ1gxa3Y0ckJmVk9iaENlNEk"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button variant="outlined" disableElevation sx={{ color: "#0067ff" }}>
            Sign Up
          </Button>
        </a>
        <a
          href="https://app.calvin-ai.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button
            variant="contained"
            disableElevation
            sx={{ background: "#0067ff" }}
          >
            Sign In
          </Button>
        </a>
      </div>
    </header>
  );
};

export default Header;
