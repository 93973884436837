import "./App.css";
import TitlePage from "./components/TitlePage/TitlePage";
import Header from "./components/Header/Header";
import ProductPage from "./components/ProductPage/ProductPage";
import ExplainerPage from "./components/ExplainerPage/ExplainerPage";
import GetStartedPage from "./components/GetStartedPage/GetStartedPage";
import FAQPage from "./components/FAQPage/FAQPage";
import Footer from "./components/Footer/Footer";

function App() {
  return (
    <div className="main-container">
      <Header />
      <div className="main-content">
        <TitlePage />
      </div>
      <ProductPage />
      <ExplainerPage />
      <GetStartedPage />
      <FAQPage />
      <Footer />
    </div>
  );
}

export default App;
