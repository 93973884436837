import React from "react";
import "./ExplainerPage.css"; // Import the CSS file for styling

export default function ExplainerPage() {
  return (
    <div className="container">
      <div className="explainer-page">
        <h1 className="explainer-title">Automate Time Consuming Notes</h1>
        <p className="sub-explainer">
          Automate the clinical note taking process with an intuitive platform
          featuring AI-powered transcription and note generation.
        </p>
        <div className="columns-container">
          <div className="column">
            <h2>Live Audio Transcription</h2>
            <p>
              Capture every word with real-time transcription. Our system
              accurately transcribes live consultations, ensuring you don't miss
              any important details.
            </p>
          </div>
          <div className="column">
            <h2>Modern Text Editor</h2>
            <p>
              Enjoy seamless editing with our advanced text editor. It's
              designed for efficiency and ease of use, enabling quick formatting
              and intuitive organisation of your notes.
            </p>
          </div>
          <div className="column">
            <h2>State-of-the-Art AI</h2>
            <p>
              Leverage cutting-edge AI technology for smart note generation. Our
              platform analyses audio content to generate concise, relevant
              notes, saving time and enhancing productivity.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
