import "./TitlePage.css";
import * as React from "react";
import Button from "@mui/material/Button";

export default function TitlePage() {
  return (
    <div className="title-page">
      <h1 className="title">Consult with Confidence, Take Notes with AI.</h1>
      <p className="sub-title">
        Automate the clinical note taking process with an intuitive platform
        featuring AI-powered transcription and note generation.
      </p>
      <a
        href="https://dev-v7a5ioospfgu6dg4.us.auth0.com/u/signup?state=hKFo2SBFTGpiV1lhdEhHVFpEakhRbVFwdXlCTm9lSWZjYzRVc6Fur3VuaXZlcnNhbC1sb2dpbqN0aWTZIFhVMEg0VnRCX09CaGhpRzdKMUhrUWg5MjdXR3luR3BKo2NpZNkgYW5hTEE3RERzam5NZHJaZ1gxa3Y0ckJmVk9iaENlNEk"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button
          variant="contained"
          disableElevation
          sx={{ background: "#0067ff", padding: "8px 18px" }}
        >
          Try Calvin AI for Free
        </Button>
      </a>
    </div>
  );
}
