import React from "react";
import "./ProductPage.css"; // Make sure to link your CSS file

const ProductPage = () => {
  return (
    <div className="product-page">
      <h1 className="product-title">Don't waste another second.</h1>
      <video className="product-video" controls>
        <source src="../../Calvin AI Video Demo.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default ProductPage;
